<template>
	<v-container>
		<v-card
			class="mx-auto pa-6"
			max-width="500px"
		>
			<v-text-field
					v-model="password"
					label="Hasło"
					clearable
					type="password"
				></v-text-field>
				<v-text-field
					max-width="500px"
					v-model="passwordSecond"
					label="Powtórz hasło"
					clearable
					type="password"
				></v-text-field>
				<div v-html="feedback"></div>
			<v-btn
			@click="resetPasswd"
			color="primary"
			:disabled="!isEmpty"
			>
				Resetuj
			</v-btn>
		</v-card>
	
	</v-container>
</template>

<script>
import io from 'socket.io-client';

export default {
	data() {
		return {
            socket: io(this.url),
			password: '',
			passwordSecond: '',
			feedback: null,
			email: '',
		}
	},
	computed: {
		isEmpty(){
			return this.password.length > 5 && this.passwordSecond.length > 5 
		}
	},
	methods: {
		resetPasswd(){
			if(this.password === this.passwordSecond){
				this.$axios.post(`${this.url}api/passwords/reset`, 
				{ password : this.password, email: this.email, resetLinkId: this.$route.params.id}).then(() => {
                    this.$router.push('/');
                })
				this.feedback = ''

			}else {
				this.feedback = 'Hasła nie są jednakowe'
			}
		},
		isExpired(){
			this.$axios.get(`${this.url}api/passwords/${this.$route.params.id}`)
			.then(el => {
				const { expired, email } = el.data;
				expired ? this.$router.push('/') : this.email = email  
			})
			.catch(() => {
				this.$router.push('/')
			})
		}
	},
	created() {
		this.isExpired()
		this.socket.on('passwordChanged', () => {
            this.isExpired()
        });
	},
}
</script>